import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  currentLanguage!: string;
  public language = signal('');

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platformID: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initLanguage();
  }

  initLanguage() {
    this.translateService.addLangs(['en', 'ar']);
    if (isPlatformBrowser(this.platformID)) {
      const lang = (
        this.storageService.language ??
        (navigator.language || window.navigator.language)
      ).includes('ar')
        ? 'ar'
        : 'en';
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
      this.setLanguage(lang);
      this.language.set(lang);
    }
  }

  changeLanguage() {
    this.translateService.currentLang == 'en'
      ? this.setLanguage('ar')
      : this.setLanguage('en');
    this.currentLanguage = this.translateService.currentLang;
    this.language.set(this.translateService.currentLang);
  }

  setLanguage(lang: string) {
    if (!lang) {
      if (isPlatformBrowser(this.platformID))
        lang =
          this.storageService.language ||
          this.translateService.getDefaultLang();
    }

    this.translateService.use(lang);
    if (isPlatformBrowser(this.platformID)) this.storageService.language = lang;

    if (lang !== 'ar') {
      this.document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      this.document
        .getElementsByTagName('html')[0]
        .setAttribute('direction', 'ltr');
      this.document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    } else {
      this.document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      this.document
        .getElementsByTagName('html')[0]
        .setAttribute('direction', 'rtl');
      this.document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
  }
}
